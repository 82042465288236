import React, { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';


const FormularioLineaGraficaYDifusion = ({ keycloak }) => {

    const navigate = useNavigate();
    const { trackPageView } = useMatomo();

    useEffect(() => {
        if (keycloak.didInitialize) {
            trackPageView();
        }
    }, [keycloak])

    useEffect(() => {
        document.title = "Colaborar con línea gráfica y difusión | RedFID Recursos";
    }, [])

    return (
        <Box style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header keycloak={keycloak} />
            <Box
                p={"0 20px"}
                component="div"
                sx={{
                    flexGrow: 1,
                    m: '30px auto',
                    maxWidth: '940px',
                    width: '100%',
                }}
            >
                {keycloak.authenticated ? (<>
                    <Typography onClick={() => {keycloak.didInitialize && navigate("/me")}} style={{ display: 'flex', alignItems: 'center', color: '#40b4ba', 'width': 'fit-content' }}>
                        <ArrowBackIcon sx={{ fontSize: '1em' }} variant="back" />
                        <Typography variant="back">Volver</Typography>
                    </Typography>
                    <Typography variant="title">Colaborar en RedFID Recursos</Typography>
                    <Typography variant='p' m={"20px auto"}>Comparta recursos de difusión, merchandising y línea gráfica de RedFID.</Typography>
                </>
                ) : keycloak.didInitialize ? (
                    <Box textAlign={"center"}>
                        <Typography variant='title' textAlign={"center"}>No tienes permiso para ver esta página.</Typography>
                        <Typography variant='subtitle' textAlign={"center"}>Por favor inicie sesión.</Typography>
                        <Typography onClick={() => {keycloak.didInitialize && navigate("/")}} variant='a'>Volver al Home</Typography>
                    </Box>
                ) : <></>}
            </Box>
            <Footer />
        </Box>
    );
}

export default FormularioLineaGraficaYDifusion;