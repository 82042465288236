import React, { useEffect, useState } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import { Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import MaterialAcademicoSummary from '../components/MaterialAcademicoSummary';
import MaterialDocenteSummary from '../components/MaterialDocenteSummary';
import api from '../../API';

const MisRecursos = ({ keycloak }) => {

  const navigate = useNavigate();
  const { trackPageView } = useMatomo();

  const [loadingRecursos, setLoadingRecursos] = useState(true)
  const [loadingActivities, setLoadingActivities] = useState(true)
  const [recursos, setRecursos] = useState(null)
  const [activities, setActivities] = useState(null)

  const loadRecursos = () => {
    axios.get(api.api_url + "/recursos_by_user/" + keycloak.idTokenParsed.preferred_username,
      { headers: { 'Authorization': 'Bearer ' + keycloak.token } }
    )
      .then(response => {
        setLoadingRecursos(false);
        setRecursos(response.data)
      })
      .catch(error => {
        console.log(error)
      });
    axios.get(api.api_url + "/activities_by_user/" + keycloak.idTokenParsed.preferred_username,
      { headers: { 'Authorization': 'Bearer ' + keycloak.token } }
    )
      .then(response => {
        setLoadingActivities(false);
        setActivities(response.data)
      })
      .catch(error => {
        console.log(error)
      });
  }

  useEffect(() => {
    if (keycloak.didInitialize && keycloak.authenticated) {
      loadRecursos();
    }
    if (keycloak.didInitialize) {
      trackPageView();
    }
  }, [keycloak.authenticated])

  useEffect(() => {
    document.title = "Mis solicitudes | RedFID Recursos";
  }, [])

  return (
    <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', alignItems: 'center' }}>
      <Header keycloak={keycloak} />
      <Grid
        component="div"
        sx={{
          flexGrow: 1,
          maxWidth: '940px',
          margin: "30px 20px"
        }}
      >
        {keycloak.authenticated ? (<>
          <Grid mb={"20px"} container display={"flex"} flexDirection={{"xs": "column", "sm": "row"}} justifyContent={"space-between"} alignItems={"center"}>
            <Box width={{"xs": "100%", "sm": "auto"}}>
              <Typography onClick={() => { keycloak.didInitialize && navigate("/") }} style={{ display: 'flex', alignItems: 'center', color: '#40b4ba', 'width': 'fit-content' }}>
                <ArrowBackIcon sx={{ fontSize: '1em' }} variant="back" />
                <Typography variant="back">Volver</Typography>
              </Typography>
              <Typography variant='title' sx={{ fontSize: { sm: '1.6em', xs: '1.2em' } }}>Mis solicitudes</Typography>
            </Box>
            <Box display={"flex"} mt={"10px"}>
              <Box mr={"5px"} onClick={() => { keycloak.didInitialize && navigate("/academico/formulario") }} sx={{ 
                "backgroundColor": "#eb947e", 
                "display": "flex", 
                "justifyContent": "space-between", 
                "alignItems": "center", 
                "padding": {"xs": "0px 10px 0px 0px", "sm": "5px 15px 5px 5px"},
                "borderRadius": "5px", 
                "&:hover": { "cursor": "pointer" } 
              }} >
                <IconButton disableFocusRipple={true} disableRipple={true} sx={{ "color": "white" }}>
                  <AddCircleOutlineIcon />
                </IconButton>
                <Typography fontSize={{"xs": "0.8em", "sm": "0.9em"}} variant={"p"} color={"white"} fontWeight={"bold"}>Investigación</Typography>
              </Box>
              <Box onClick={() => { keycloak.didInitialize && navigate("/docente/formulario") }} sx={{ 
                "backgroundColor": "#eb947e", 
                "display": "flex", 
                "justifyContent": "space-between", 
                "alignItems": "center", 
                "padding": {"xs": "0px 10px 0px 0px", "sm": "5px 15px 5px 5px"},
                "borderRadius": "5px", 
                "&:hover": { "cursor": "pointer" } 
              }} >
                <IconButton disableFocusRipple={true} disableRipple={true} sx={{ "color": "white" }}>
                  <AddCircleOutlineIcon />
                </IconButton>
                <Typography fontSize={{"xs": "0.8em", "sm": "0.9em"}} variant={"p"} color={"white"} fontWeight={"bold"}>Material docente</Typography>
              </Box>
            </Box>
          </Grid>
          <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant='p' mb={"20px"}>Explora los recursos que has compartido en la comunidad, y el estado de tus propuestas para compartir textos de investigación y/o material docente.</Typography>
          {(!loadingActivities && !loadingRecursos && (activities.length > 0)) && (

            activities.map((item, index) => (
              <MaterialDocenteSummary keycloak={keycloak} key={index} item={item} showTag={true} isMine={true} />
            ))
          )}
          {(!loadingActivities && !loadingRecursos && (recursos.length > 0)) && (
            recursos.map((item, index) => (
              <MaterialAcademicoSummary keycloak={keycloak} key={index} item={item} isMine={true} />
            ))
          )}
          {(!loadingActivities && !loadingRecursos && (recursos.length === 0 && activities.length === 0)) && (
            <Box>
              <Typography fontSize={{"xs": "0.7em", "sm": "0.85em"}} variant={"p"} textAlign={"center"}> Aún no has propuesto ningún recurso.</Typography>
            </Box>
          )}
          {(loadingRecursos || loadingActivities) && (
            <Box textAlign={"center"}>
              <CircularProgress sx={{ color: "#40b4ba" }} />
            </Box>
          )}
        </>) : keycloak.didInitialize ? (<>
          <Box textAlign={"center"}>
            <Typography variant='title' textAlign={"center"}>No tienes permiso para ver esta página.</Typography>
            <Typography variant='subtitle' textAlign={"center"}>Por favor inicie sesión.</Typography>
            <Typography onClick={() => { keycloak.didInitialize && navigate("/") }} variant='a'>Volver al Home</Typography>
          </Box>
        </>) : <></>}
      </Grid>
      <Footer />
    </Grid>
  );
}

export default MisRecursos;