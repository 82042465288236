import React, { useState, useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import axios from 'axios';
import Box from '@mui/material/Box';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import MaterialDocenteSummary from '../components/MaterialDocenteSummary';
import api from '../../API';
import { useNavigate } from 'react-router-dom';


function CustomTabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid>
          {children}
        </Grid>
      )}
    </div>
  );
}


const MaterialDocente = ({ keycloak }) => {

  const navigate = useNavigate();
  const { trackPageView } = useMatomo();

  const [loadingMaterialDocente, setLoadingMaterialDocente] = useState(true)
  const [materialDocente, setMaterialDocente] = useState(null)
  const [tab, setTab] = useState("matematica")


  const loadMaterialDocente = (visibility) => {
    const header = keycloak.authenticated ? { headers: { 'Authorization': 'Bearer ' + keycloak.token } } : {}
    axios.get(api.api_url + (visibility === "all" ? "/activities_private/" : "/activities/"), header)
      .then(response => {
        setLoadingMaterialDocente(false);
        setMaterialDocente(response.data)
      })
      .catch(error => {
        console.log(error)
      });
  }

  const handleTabs = (event, newValue) => {
    setTab(newValue);
  }

  useEffect(() => {
    if (keycloak.didInitialize && keycloak.authenticated) {
      loadMaterialDocente("all");
    } else {
      loadMaterialDocente("public");
    }
    if (keycloak.didInitialize) {
      trackPageView();
    }
  }, [keycloak.authenticated])

  useEffect(() => {
    document.title = "Material docente | RedFID Recursos";
  }, [])

  return (
    <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', alignItems: 'center' }}>
      <Header keycloak={keycloak} />
      <Grid
        component="div"
        sx={{
          flexGrow: 1,
          maxWidth: '940px',
          margin: "30px 20px",
        }}
      >
        <Grid mb={"20px"} container display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <Box>
            <Typography onClick={() => {keycloak.didInitialize && navigate("/")}} style={{ display: 'flex', alignItems: 'center', color: '#40b4ba', 'width': 'fit-content' }}>
              <ArrowBackIcon sx={{ fontSize: '1em' }} variant="back" />
              <Typography variant="back">Volver</Typography>
            </Typography>
            <Typography variant='title' sx={{ fontSize: { sm: '1.6em', xs: '1.2em' } }}>Material docente</Typography>
          </Box>
          {keycloak.authenticated &&
              <Box onClick={() => {keycloak.didInitialize && navigate("/me")}} sx={{ "backgroundColor": "#40b4ba", "display": "flex", "justifyContent": "space-between", "alignItems": "center", "padding": {"xs": "0px 10px 0px 0px", "sm": "5px 15px 5px 5px"}, "borderRadius": "5px", "&:hover": { "cursor": "pointer" } }} >
                <IconButton disableFocusRipple={true} disableRipple={true} sx={{ "color": "white" }}>
                  <DriveFolderUploadIcon />
                </IconButton>
                <Typography fontSize={{"xs": "0.8em", "sm": "0.9em"}} color={"white"} fontWeight={"bold"}>Colaborar</Typography>
              </Box>
          }
        </Grid>
        <Typography variant='p' mb={"20px"}>Accede a material docente relacionado con la enseñanza de la matemática, didáctica de la matemática,  formación pedagógica y formación práctica de los profesores en formación.</Typography>
        <Tabs TabIndicatorProps={{ sx: { display: 'none' } }} value={tab} onChange={handleTabs} centered variant='fullWidth' sx={{
          "&& .Mui-selected": {
            color: '#646464',
            backgroundColor: '#e1f1f2',
          },
          '& .MuiTabs-flexContainer': {
            flexWrap: 'wrap',
          }
        }}>
          <Tab sx={{"minWidth": "180px"}} label={
            <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
              Matemática
            </div>
          }
            value={"matematica"} />
          <Tab sx={{"minWidth": "180px"}}
            label={
              <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
                Didáctica de <br /> la matemática
              </div>
            }
            value={"didactica_de_la_matematica"}
          />
          <Tab sx={{"minWidth": "180px"}}
            label={
              <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
                Formación <br /> pedagógica
              </div>
            }
            value={"formacion_pedagogica"}
          />

          <Tab sx={{"minWidth": "180px"}}
            label={
              <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
                Formación <br /> práctica
              </div>
            }
            value={"formacion_practica"}
          />
          <Tab sx={{"minWidth": "180px"}}
            label={
              <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
                Otras <br /> disciplinas
              </div>
            }
            value={"other"}
          />
        </Tabs>
        {loadingMaterialDocente ? <Box textAlign={"center"}><CircularProgress sx={{ "color": "#40b4ba" }} /></Box> : !materialDocente ? <Box><Typography textAlign={"center"} variant="p">¡Próximamente!</Typography></Box> :
          materialDocente.length === 0 ? <Box><Typography textAlign={"center"} variant="p">¡Próximamente!</Typography></Box> : (
            <>
              <CustomTabPanel value={tab} index={"matematica"}>
              {materialDocente.filter(item => item.classification === tab).length === 0 && <Typography textAlign={"center"} variant="p">¡Próximamente!</Typography>}
              {materialDocente
                  .filter(item => item.classification === tab)
                  .map((item, index) => (
                    <MaterialDocenteSummary keycloak={keycloak} key={index} item={item} showTag={false} isMine={false} />
                  ))}
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={"didactica_de_la_matematica"}>
              {materialDocente.filter(item => item.classification === tab).length === 0 && <Typography textAlign={"center"} variant="p">¡Próximamente!</Typography>}
              {materialDocente
                  .filter(item => item.classification === tab)
                  .map((item, index) => (
                    <MaterialDocenteSummary keycloak={keycloak} key={index} item={item} showTag={false} isMine={false} />
                  ))}
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={"formacion_pedagogica"}>
              {materialDocente.filter(item => item.classification === tab).length === 0 && <Typography textAlign={"center"} variant="p">¡Próximamente!</Typography>}
              {materialDocente
                  .filter(item => item.classification === tab)
                  .map((item, index) => (
                    <MaterialDocenteSummary keycloak={keycloak} key={index} item={item} showTag={false} isMine={false} />
                  ))}
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={"formacion_practica"}>
              {materialDocente.filter(item => item.classification === tab).length === 0 && <Typography textAlign={"center"} variant="p">¡Próximamente!</Typography>}
              {materialDocente
                  .filter(item => item.classification === tab)
                  .map((item, index) => (
                    <MaterialDocenteSummary keycloak={keycloak} key={index} item={item} showTag={false} isMine={false} />
                  ))}
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={"other"}>
              {materialDocente.filter(item => item.classification === tab).length === 0 && <Typography textAlign={"center"} variant="p">¡Próximamente!</Typography>}
                {materialDocente
                  .filter(item => item.classification === tab)
                  .map((item, index) => (
                    <MaterialDocenteSummary keycloak={keycloak} key={index} item={item} showTag={false} isMine={false} />
                  ))}
              </CustomTabPanel>
            </>
          )}
      </Grid>
      <Footer />
    </Grid>
  );
}

export default MaterialDocente;