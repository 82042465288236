import React, { useState, useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import MaterialAcademicoSummary from '../components/MaterialAcademicoSummary';
import api from '../../API';


function CustomTabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid>
          {children}
        </Grid>
      )}
    </div>
  );
}


const MaterialAcademico = ({ keycloak }) => {

  const navigate = useNavigate();
  const { trackPageView } = useMatomo();

  const [loadingMaterialAcademico, setLoadingMaterialAcademico] = useState(true)
  const [materialAcademico, setMaterialAcademico] = useState(null)
  const [tab, setTab] = useState("educacion_matematica")


  const loadMaterialAcademico = (visibility) => {
    const header = keycloak.authenticated ? { headers: { 'Authorization': 'Bearer ' + keycloak.token } } : {}
    axios.get(api.api_url + (visibility === "all" ? "/investigacion_private/" : "/investigacion/"), header)
      .then(response => {
        setLoadingMaterialAcademico(false);
        setMaterialAcademico(response.data)
      })
      .catch(error => {
        console.log(error)
      });
  }

  const handleTabs = (event, newValue) => {
    setTab(newValue);
  }

  useEffect(() => {
    if (keycloak.authenticated) {
      loadMaterialAcademico("all");
    } else {
      loadMaterialAcademico("public");
    }
    if (keycloak.didInitialize) {
      trackPageView();
    }
  }, [keycloak.authenticated])

  useEffect(() => {
    document.title = "Investigación | RedFID Recursos";
  }, [])

  return (
    <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', alignItems: 'center' }}>
      <Header keycloak={keycloak} />
      <Grid
        component="div"
        sx={{
          flexGrow: 1,
          maxWidth: '940px',
          margin: "30px 20px",
        }}
      >
        <Grid mb={"20px"} container display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <Box>
            <Typography onClick={() => {keycloak.didInitialize && navigate("/")}} style={{ display: 'flex', alignItems: 'center', color: '#40b4ba', 'width': 'fit-content' }}>
              <ArrowBackIcon sx={{ fontSize: '1em' }} variant="back" />
              <Typography variant="back">Volver</Typography>
            </Typography>
            <Typography variant='title' sx={{ fontSize: { sm: '1.6em', xs: '1.2em' } }}>Material académico</Typography>
          </Box>
          {keycloak.authenticated &&
            <Box onClick={() => {keycloak.didInitialize && navigate("/me")}}>
              <Box sx={{ "backgroundColor": "#40b4ba", "display": "flex", "justifyContent": "space-between", "alignItems": "center", "padding": {"xs": "0px 10px 0px 0px", "sm": "5px 15px 5px 5px"}, "borderRadius": "5px", "&:hover": { "cursor": "pointer" } }} >
                <IconButton disableFocusRipple={true} disableRipple={true} sx={{ "color": "white" }}>
                  <DriveFolderUploadIcon />
                </IconButton>
                <Typography fontSize={{"xs": "0.8em", "sm": "0.9em"}} color={"white"} fontWeight={"bold"}>Colaborar</Typography>
              </Box>
            </Box>
          }
        </Grid>
        <Typography variant='p' mb={"20px"}>Accede a material académico relacionados con la educación matemática, formación docente inicial de profesores de matemática y al aprendizaje profesional de los formadores.</Typography>
        <Tabs TabIndicatorProps={{ sx: { display: 'none' } }} value={tab} onChange={handleTabs} centered variant='fullWidth' sx={{
          "&& .Mui-selected": {
            color: '#646464',
            backgroundColor: '#e1f1f2',
          },
          '& .MuiTabs-flexContainer': {
            flexWrap: 'wrap',
          }
        }}>
          <Tab sx={{"minWidth": "184px"}} label={
            <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
              Educación <br /> matemática
            </div>
          }
            value={"educacion_matematica"} />
          <Tab
           sx={{"minWidth": "184px"}}
            label={
              <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
                Formación de <br /> profesores
              </div>
            }
            value={"formacion_profesores"}
          />
          <Tab
           sx={{"minWidth": "184px"}}
            label={
              <div style={{"fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
                Aprendizaje <br /> profesional
              </div>
            }
            value={"aprendizaje_formadores"}
          />

          <Tab
           sx={{"minWidth": "184px"}}
            label={
              <div style={{"fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
                Material de cursos <br /> y talleres RedFID
              </div>
            }
            value={"cursos_talleres_redfid"}
          />
          <Tab
           sx={{"minWidth": "184px"}}
            label={
              <div style={{"fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
                Otros
              </div>
            }
            value={"other"}
          />
        </Tabs>
        {loadingMaterialAcademico ? <Box textAlign={"center"}><CircularProgress sx={{ "color": "#40b4ba" }} /></Box> : !materialAcademico ? <Box><Typography textAlign={"center"} variant="p">¡Próximamente!</Typography></Box> :
          materialAcademico.length === 0 ? <Box><Typography textAlign={"center"} variant="p">¡Próximamente!</Typography></Box> : (
            <>
              <CustomTabPanel value={tab} index={"educacion_matematica"}>
              {materialAcademico.filter(item => item.category === tab).length === 0 && <Typography textAlign={"center"} variant="p">¡Próximamente!</Typography>}
              {materialAcademico
                  .filter(item => item.category === tab)
                  .map((item, index) => (
                    <MaterialAcademicoSummary keycloak={keycloak} key={index} item={item} isMine={false} />
                  ))}
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={"formacion_profesores"}>
              {materialAcademico.filter(item => item.category === tab).length === 0 && <Typography textAlign={"center"} variant="p">¡Próximamente!</Typography>}
              {materialAcademico
                  .filter(item => item.category === tab)
                  .map((item, index) => (
                    <MaterialAcademicoSummary keycloak={keycloak} key={index} item={item} isMine={false} />
                  ))}
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={"aprendizaje_formadores"}>
              {materialAcademico.filter(item => item.category === tab).length === 0 && <Typography textAlign={"center"} variant="p">¡Próximamente!</Typography>}
              {materialAcademico
                  .filter(item => item.category === tab)
                  .map((item, index) => (
                    <MaterialAcademicoSummary keycloak={keycloak} key={index} item={item} isMine={false} />
                  ))}
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={"cursos_talleres_redfid"}>
              {materialAcademico.filter(item => item.category === tab).length === 0 && <Typography textAlign={"center"} variant="p">¡Próximamente!</Typography>}
              {materialAcademico
                  .filter(item => item.category === tab)
                  .map((item, index) => (
                    <MaterialAcademicoSummary keycloak={keycloak} key={index} item={item} isMine={false} />
                  ))}
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={"other"}>
              {materialAcademico.filter(item => item.category === tab).length === 0 && <Typography textAlign={"center"} variant="p">¡Próximamente!</Typography>}
                {materialAcademico
                  .filter(item => item.category === tab)
                  .map((item, index) => (
                    <MaterialAcademicoSummary keycloak={keycloak} key={index} item={item} isMine={false} />
                  ))}
              </CustomTabPanel>
            </>
          )}
      </Grid>
      <Footer />
    </Grid>
  );
}

export default MaterialAcademico;