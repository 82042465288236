import React, { useState, useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import Box from '@mui/material/Box';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LineaGraficaYDifusionSummary from '../components/LineaGraficaYDifusionSummary';
import api from '../../API';
import { useNavigate } from 'react-router-dom';

function CustomTabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid>
          {children}
        </Grid>
      )}
    </div>
  );
}

const LineaGraficaYDifusion = ({ keycloak }) => {

  const navigate = useNavigate();
  const { trackPageView } = useMatomo()

  const [loadingLineaGraficaYDifusion, setLoadingLineaGraficaYDifusion] = useState(false)
  const [lineaGraficaYDifusion, setLineaGraficaYDifusion] = useState(null)
  const [tab, setTab] = useState("material_difusion")

  const loadLineaGraficaYDifusion = (visibility) => {
  }

  const handleTabs = (event, newValue) => {
    setTab(newValue);
  }

  useEffect(() => {
    if (keycloak.didInitialize && keycloak.authenticated) {
      loadLineaGraficaYDifusion("all");
    } else {
      loadLineaGraficaYDifusion("public");
    }
    if (keycloak.didInitialize) {
      trackPageView();
    }
  }, [keycloak.authenticated])

  useEffect(() => {
    document.title = "Línea gráfica y difusión | RedFID Recursos";
  }, [])

  return (
    <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', alignItems: 'center' }}>
      <Header keycloak={keycloak} />
      <Grid
        component="div"
        sx={{
          flexGrow: 1,
          maxWidth: '940px',
          margin: "30px 20px",
        }}
      >
        <Grid mb={"20px"} container display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <Box>
            <Typography onClick={() => { keycloak.didInitialize && navigate("/") }} style={{ display: 'flex', alignItems: 'center', color: '#40b4ba', 'width': 'fit-content' }}>
              <ArrowBackIcon sx={{ fontSize: '1em' }} variant="back" />
              <Typography variant="back">Volver</Typography>
            </Typography>
            <Typography variant='title' sx={{ fontSize: { sm: '1.6em', xs: '1.2em' } }}>Línea gráfica y difusión</Typography>
          </Box>
          {keycloak.authenticated &&
            <Box onClick={() => { keycloak.didInitialize && navigate("/me") }} sx={{ "backgroundColor": "#40b4ba", "display": "flex", "justifyContent": "space-between", "alignItems": "center", "padding": {"xs": "0px 10px 0px 0px", "sm": "5px 15px 5px 5px"}, "borderRadius": "5px", "&:hover": { "cursor": "pointer" } }} >
              <IconButton disableFocusRipple={true} disableRipple={true} sx={{ "color": "white" }}>
                <DriveFolderUploadIcon />
              </IconButton>
              <Typography fontSize={{"xs": "0.8em", "sm": "0.9em"}} color={"white"} fontWeight={"bold"}>Colaborar</Typography>
            </Box>
          }
        </Grid>
        <Typography variant='p' mb={"20px"}>Acceda a material de difusión, merchandising y línea gráfica de RedFID.</Typography>
        <Tabs TabIndicatorProps={{ sx: { display: 'none' } }} value={tab} onChange={handleTabs} centered variant='fullWidth' sx={{
          "&& .Mui-selected": {
            color: '#646464',
            backgroundColor: '#e1f1f2',
          },
          '& .MuiTabs-flexContainer': {
            flexWrap: 'wrap',
          }
        }}>
          <Tab sx={{"minWidth": "225px"}} label={
            <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
              Material de <br /> difusión
            </div>
          }
            value={"material_difusion"} />
          <Tab sx={{"minWidth": "225px"}}
            label={
              <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
                Línea <br /> gráfica
              </div>
            }
            value={"linea_grafica"}
          />
          <Tab sx={{"minWidth": "225px"}}
            label={
              <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
                Actividades
              </div>
            }
            value={"actividades"}
          />
          <Tab sx={{"minWidth": "225px"}}
            label={
              <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
                Otros
              </div>
            }
            value={"other"}
          />
        </Tabs>
        {loadingLineaGraficaYDifusion ? <Box textAlign={"center"}><CircularProgress sx={{ "color": "#40b4ba" }} /></Box> : !lineaGraficaYDifusion ? <Box><Typography textAlign={"center"} variant="p">¡Próximamente!</Typography></Box> :
          lineaGraficaYDifusion.length === 0 ? <Box><Typography textAlign={"center"} variant="p">¡Próximamente!</Typography></Box> : (
            <>
              <CustomTabPanel value={tab} index={"material_difusion"}>
                {lineaGraficaYDifusion
                  .filter(item => item.category === tab)
                  .map((item, index) => (
                    <LineaGraficaYDifusionSummary keycloak={keycloak} key={index} item={item} />
                  ))}
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={"linea_grafica"}>
                {lineaGraficaYDifusion
                  .filter(item => item.category === tab)
                  .map((item, index) => (
                    <LineaGraficaYDifusionSummary keycloak={keycloak} key={index} item={item} />
                  ))}
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={"actividades"}>
                {lineaGraficaYDifusion
                  .filter(item => item.category === tab)
                  .map((item, index) => (
                    <LineaGraficaYDifusionSummary keycloak={keycloak} key={index} item={item} />
                  ))}
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={"other"}>
                {lineaGraficaYDifusion
                  .filter(item => item.category === tab)
                  .map((item, index) => (
                    <LineaGraficaYDifusionSummary keycloak={keycloak} key={index} item={item} />
                  ))}
              </CustomTabPanel>
            </>
          )}
      </Grid>
      <Footer />
    </Grid>
  );
}

export default LineaGraficaYDifusion;