import React, { useState, useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import axios from 'axios';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import { formatFileSize } from '../../utils/formatters';
import { concatAuthors, removeHtmlTags } from '../../utils/stringHandlers';
import FileUpload from '../../utils/fileUpload';
import { useNavigate } from 'react-router-dom';
import api from '../../API';

const FormularioMaterialAcademico = ({ keycloak }) => {

    const navigate = useNavigate();
    const { trackPageView } = useMatomo();

    const [consent, setConsent] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formFailed, setFormFailed] = useState(false);
    const [formError, setFormError] = useState('');
    const [loadingDOI, setLoadingDOI] = useState(false);
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [author, setAuthor] = useState('');
    const [description, setDescription] = useState('');
    const [visibility, setVisibility] = useState('none');
    const [file, setFile] = useState(null);
    const [resourceUrl, setResourceUrl] = useState('');
    const [category, setCategory] = useState('none');
    const [subcategory, setSubcategory] = useState('none');
    const [reference, setReference] = useState('none');
    const [doi, setDOI] = useState('');
    const [editor, setEditor] = useState('');
    const [journal, setJournal] = useState('');
    const [year, setYear] = useState('');
    const [volume, setVolume] = useState('');
    const [issue, setIssue] = useState('');
    const [pages, setPages] = useState('');
    const [justification, setJustification] = useState('');
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let tempErrors = {};
        let isValid = true;
        if (category === 'none') {
            tempErrors.category = "Por favor indique la categoría del recurso.";
            isValid = false;
        }
        if (subcategory === 'none') {
            tempErrors.subcategory = "Por favor indique el tipo del recurso.";
            isValid = false;
        }
        if (visibility === 'none') {
            tempErrors.visibility = "Por favor seleccione una visibilidad para el recurso.";
            isValid = false;
        }
        if (subcategory !== "website" && reference === 'none') {
            tempErrors.reference = "Por favor seleccione una referencia para el recurso.";
            isValid = false;
        }
        if (subcategory !== "website" && reference === 'doi' && !doi) {
            tempErrors.doi = "Por favor ingrese un DOI válido.";
            isValid = false;
        }
        if (subcategory !== "website" && reference === 'direct') {
            if (!file) {
                tempErrors.file = "Por favor suba el archivo del recurso.";
                isValid = false;
            } else if (!file.name.endsWith(".pdf")) {
                tempErrors.file = "Por favor seleccione un archivo PDF.";
                isValid = false;
            } else if (file.size > 1024 * 1024 * 1024) {
                tempErrors.file = "El tamaño máximo del archivo es de 1 GB.";
                isValid = false;
            }
        }
        if (reference === 'url') {
            const urlPattern = new RegExp(
                "^" +
                  // protocol identifier (optional)
                  // short syntax // still required
                  "(?:(?:(?:https?|ftp):)?\\/\\/)" +
                  // user:pass BasicAuth (optional)
                  "(?:\\S+(?::\\S*)?@)?" +
                  "(?:" +
                    // IP address exclusion
                    // private & local networks
                    "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
                    "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
                    "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
                    // IP address dotted notation octets
                    // excludes loopback network 0.0.0.0
                    // excludes reserved space >= 224.0.0.0
                    // excludes network & broadcast addresses
                    // (first & last IP address of each class)
                    "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
                    "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
                    "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
                  "|" +
                    // host & domain names, may end with dot
                    // can be replaced by a shortest alternative
                    // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
                    "(?:" +
                      "(?:" +
                        "[a-z0-9\\u00a1-\\uffff]" +
                        "[a-z0-9\\u00a1-\\uffff_-]{0,62}" +
                      ")?" +
                      "[a-z0-9\\u00a1-\\uffff]\\." +
                    ")+" +
                    // TLD identifier name, may end with dot
                    "(?:[a-z\\u00a1-\\uffff]{2,}\\.?)" +
                  ")" +
                  // port number (optional)
                  "(?::\\d{2,5})?" +
                  // resource path (optional)
                  "(?:[/?#]\\S*)?" +
                "$", "i"
              );
            if (!urlPattern.test(resourceUrl)) {
                tempErrors.resourceUrl = "Por favor ingrese una URL válida.";
                isValid = false;
            }
        }
        if (title.length > 500 || title.length < 5) {
            tempErrors.title = "El título debe tener entre 5 y 500 caracteres (actual: " + title.length + ").";
            isValid = false;
        }
        if (!title) {
            tempErrors.title = "Por favor indique el título del recurso.";
            isValid = false;
        }
        if (author && (author.length > 500 || author.length < 3)) {
            tempErrors.author = "El autor debe tener entre 3 y 500 caracteres (actual: " + author.length + ").";
            isValid = false;
        }
        if (!author) {
            tempErrors.author = "Por favor indique el autor de este material.";
            isValid = false;
        }
        if (subtitle && (subtitle.length > 500 || subtitle.length < 5)) {
            tempErrors.subtitle = "El subtítulo debe tener entre 5 y 500 caracteres (actual: " + subtitle.length + ").";
            isValid = false;
        }
        if (description && (description.length > 10000 || description.length < 10)) {
            tempErrors.description = "La descripción/abstract debe tener entre 10 y 10000 caracteres (actual: " + description.length + ").";
            isValid = false;
        }
        if (editor && (editor.length > 500 || editor.length < 3)) {
            tempErrors.editor = "El editor debe tener entre 3 y 500 caracteres (actual: " + editor.length + ").";
            isValid = false;
        }
        if (journal && (journal.length > 500 || journal.length < 3)) {
            tempErrors.journal = "La revista debe tener entre 3 y 500 caracteres (actual: " + journal.length + ").";
            isValid = false;
        }
        if (year && (year.length > 4 || year.length < 4)) {
            tempErrors.year = "El año debe tener 4 caracteres.";
            isValid = false;
        }
        if (!year) {
            tempErrors.year = "Por favor indique el año de publicación del recurso.";
            isValid = false;
        }
        if (volume && (volume.length > 20 || volume.length < 1)) {
            tempErrors.volume = "El volumen debe tener entre 1 y 20 caracteres (actual: " + volume.length + ").";
            isValid = false;
        }
        if (issue && (issue.length > 20 || issue.length < 1)) {
            tempErrors.issue = "El número debe tener entre 1 y 20 caracteres (actual: " + issue.length + ").";
            isValid = false;
        }
        if (pages && (pages.length > 20 || pages.length < 1)) {
            tempErrors.pages = "Las páginas deben tener entre 1 y 20 caracteres (actual: " + pages.length + ").";
            isValid = false;
        }
        if (justification.length > 10000 || justification.length < 10) {
            tempErrors.justification = "La forma de citar debe tener entre 10 y 10000 caracteres (actual: " + justification.length + ").";
            isValid = false;
        }
        if (!justification) {
            tempErrors.justification = "Por favor indique cómo citar este recurso.";
            isValid = false;
        }
        if (!consent) {
            tempErrors.consent = "Por favor declare que está autorizado/a para compartir este material.";
            isValid = false;
        }
        setErrors({ ...tempErrors });
        if (!isValid) {
            setFormError("Hay errores en el formulario.");
            setFormFailed(true);
        }
        return isValid;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setFormLoading(true);
        setFormFailed(false);
        setFormError('');
        if (validateForm()) {
            var url;
            const formData = new FormData();
            formData.append('username', keycloak.idTokenParsed.preferred_username);
            formData.append('first_name', keycloak.idTokenParsed.given_name);
            formData.append('last_name', keycloak.idTokenParsed.family_name);
            formData.append('classification', 'material_academico');
            formData.append('category', category);
            formData.append('subcategory', subcategory);
            formData.append('visibility', visibility);
            formData.append('justification', justification);
            if (subcategory === 'article') {
                url = '/article/';
                formData.append('reference', reference);
                formData.append('doi', doi);
                formData.append('url', resourceUrl);
                formData.append('title', title);
                formData.append('author', author);
                formData.append('abstract', description);
                formData.append('editor', editor);
                formData.append('journal', journal);
                formData.append('year', year);
                formData.append('volume', volume);
                formData.append('issue', issue);
                formData.append('pages', pages);
            } else if (subcategory === 'congress_minute' || subcategory === 'technical_report') {
                url = '/' + subcategory + "/";
                formData.append('reference', reference);
                formData.append('doi', doi);
                formData.append('url', resourceUrl);
                formData.append('filename', file ? file.name : "");
                formData.append('filesize', file ? file.size.toString() : "");
                if (file) {
                    formData.append('file', file);
                }
                formData.append('title', title);
                formData.append('subtitle', subtitle);
                formData.append('author', author);
                formData.append('abstract', description);
                formData.append('editor', editor);
                formData.append('journal', journal);
                formData.append('year', year);
                formData.append('pages', pages);
            } else if (subcategory === 'poster') {
                url = '/poster/';
                formData.append('reference', reference);
                formData.append('doi', doi);
                formData.append('url', resourceUrl);
                formData.append('filename', file ? file.name : "");
                formData.append('filesize', file ? file.size.toString() : "");
                if (file) {
                    formData.append('file', file);
                }
                formData.append('title', title);
                formData.append('subtitle', subtitle);
                formData.append('author', author);
                formData.append('abstract', description);
                formData.append('year', year);
            } else if (subcategory === 'book') {
                url = '/book/';
                formData.append('reference', reference);
                formData.append('doi', doi);
                formData.append('url', resourceUrl);
                formData.append('title', title);
                formData.append('subtitle', subtitle);
                formData.append('author', author);
                formData.append('abstract', description);
                formData.append('editor', editor);
                formData.append('year', year);
            } else if (subcategory === 'book_chapter') {
                url = '/book_chapter/';
                formData.append('reference', reference);
                formData.append('doi', doi);
                formData.append('url', resourceUrl);
                formData.append('title', title);
                formData.append('subtitle', subtitle);
                formData.append('author', author);
                formData.append('abstract', description);
                formData.append('editor', editor);
                formData.append('pages', pages);
                formData.append('year', year);
            } else if (subcategory === 'website') {
                url = '/website/';
                formData.append('url', resourceUrl);
                formData.append('title', title);
                formData.append('subtitle', subtitle);
                formData.append('description', description);
                formData.append('author', author);
                formData.append('year', year);
            }
            if (!url) {
                console.error("Error: Subcategory not found");
                return;
            }
            axios.post(api.api_url + url, formData, { headers: { 'Authorization': 'Bearer ' + keycloak.token } })
                .then(response => {
                    setFormSubmitted(true);
                    setFormLoading(false);
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        setFormError('Error: ' + error.response.data.message);
                    } else if (error.response.status === 490) {
                        setFormError('No se encuentra el usuario. Este error no debería ocurrir. Por favor contacte a administración.');
                    } else if (error.response.status  === 491) {
                        setFormError('Este recurso ya se encuentra en RedFID Recursos.');
                    } else {
                        setFormError('Ha ocurrido un error desconocido. Por favor contacte a administración.');
                    }
                    setFormFailed(true);
                    setFormLoading(false);
                });
        } else {
            setFormLoading(false);
        }
    };

    const resetForm = () => {
        setConsent(false);
        setFormLoading(false);
        setFormSubmitted(false);
        setFormFailed(false);
        setFormError('');
        setTitle('');
        setSubtitle('');
        setAuthor('');
        setDescription('');
        setVisibility('none');
        setFile(null);
        setResourceUrl('');
        setCategory('none');
        setSubcategory('none');
        setReference('none');
        setDOI('');
        setEditor('');
        setJournal('');
        setYear('');
        setVolume('');
        setIssue('');
        setPages('');
        setJustification('');
        setErrors({});
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        setErrors(prevErrors => ({ ...prevErrors, file: '' }));
    };

    const resetFileInput = () => {
        setFile(null);
        setErrors(prevErrors => ({ ...prevErrors, file: '' }));
    };

    async function handleDOI(doi) {
        const Cite = require('citation-js');
        if (!doi) {
            return;
        }
        setLoadingDOI(true);
        try {
            const data = await Cite.async(doi);
            const authors = data["data"][0].author
            setTitle(data["data"][0].title ? data["data"][0].title : "");
            setAuthor(authors ? concatAuthors(authors) : "");
            setDescription(removeHtmlTags(data["data"][0].abstract ? data["data"][0].abstract : ""));
            setEditor(data["data"][0].publisher ? data["data"][0].publisher : "");
            setJournal(data["data"][0]["container-title"] ? data["data"][0]["container-title"] : "");
            setYear(data["data"][0].issued["date-parts"][0][0] ? data["data"][0].issued["date-parts"][0][0] : "");
            setVolume(data["data"][0].volume ? data["data"][0].volume : "");
            setIssue(data["data"][0].issue ? data["data"][0].issue : "");
            setPages(data["data"][0].page ? data["data"][0].page : "");
            var justificacion = "";
            if (authors) {
                justificacion += concatAuthors(authors) + " ";
            }
            if (data["data"][0].issued["date-parts"][0][0]) {
                justificacion += "(" + data["data"][0].issued["date-parts"][0][0] + "). ";
            }
            if (data["data"][0].title) {
                justificacion += data["data"][0].title + ". ";
            }
            if (data["data"][0]["container-title"]) {
                justificacion += data["data"][0]["container-title"] + ", ";
            }
            if (data["data"][0].volume) {
                justificacion += data["data"][0].volume;
            }
            if (data["data"][0].issue) {
                if (data["data"][0].volume) {
                    justificacion += "(" + data["data"][0].issue + "), ";
                }
            }
            if (data["data"][0].page) {
                justificacion += data["data"][0].page + ".";
            }
            if (justificacion.endsWith(",")) {
                justificacion = justificacion.slice(0, -1) + ".";
            }
            if (justificacion.endsWith(", ")) {
                justificacion = justificacion.slice(0, -2) + ".";
            }
            setJustification(justificacion);
            setErrors(prevErrors => ({ ...prevErrors, doi: '' }));
        } catch (error) {
            if (error.message.includes('Server responded with status code 404')) {
                setErrors(prevErrors => ({ ...prevErrors, doi: 'Artículo no encontrado.' }));
            } else if (error.message.includes('This format is not supported or recognized')) {
                setErrors(prevErrors => ({ ...prevErrors, doi: 'DOI inválido.' }));
            } else {
                console.error(error);
                setErrors(prevErrors => ({ ...prevErrors, doi: 'Ha ocurrido un error, no se pudo obtener la información del DOI.' }));
            }
            setTitle('');
            setAuthor('');
            setDescription('');
            setEditor('');
            setJournal('');
            setVolume('');
            setIssue('');
            setPages('');
        }
        setLoadingDOI(false);
    }

    useEffect(() => {
        if (keycloak.didInitialize) {
            trackPageView();
        }
    }, [keycloak])

    useEffect(() => {
        document.title = "Colaborar con material de investigación | RedFID Recursos";
    }, [])

    return (
        <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', alignItems: 'center' }}>
            <Header keycloak={keycloak} />
            <Grid
                component="div"
                sx={{
                flexGrow: 1,
                maxWidth: '940px',
                width: '100%',
                padding: "30px 20px",
                }}
            >
                {keycloak.authenticated ? (<>
                    <Typography onClick={() => {keycloak.didInitialize && navigate("/me")}} style={{ display: 'flex', alignItems: 'center', color: '#40b4ba', 'width': 'fit-content' }}>
                        <ArrowBackIcon sx={{ fontSize: '1em' }} variant="back" />
                        <Typography variant="back">Volver</Typography>
                    </Typography>
                    <Typography sx={{"fontSize": {"xs": "1em", "sm": "1.4em"}}} variant="title">Colaborar con material de investigación</Typography>
                    <Typography fontSize={{"xs": "0.8em", "sm": "0.85em"}} variant='p' m={"20px auto"}>Comparta material académico con el resto de la comunidad.</Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container columnSpacing={2} alignItems="center">
                            <Grid item xs={12} sm={4} mt={"20px"}>
                                <TextField
                                    select
                                    fullWidth
                                    label="Categoría"
                                    value={category}
                                    onChange={(e) => {
                                        setCategory(e.target.value);
                                        setErrors(prevErrors => ({ ...prevErrors, category: '', subcategory: '' }));
                                    }}
                                    error={!!errors.category}
                                    helperText={errors.category}
                                    variant="outlined"
                                    disabled={formLoading || formSubmitted}
                                >
                                    <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                                    <MenuItem value="educacion_matematica">Educación matemática</MenuItem>
                                    <MenuItem value="formacion_profesores">Formación de profesores</MenuItem>
                                    <MenuItem value="aprendizaje_formadores">Aprendizaje profesional de formadores</MenuItem>
                                    <MenuItem value="other">Otros</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={4} mt={"20px"}>
                                <TextField
                                    select
                                    fullWidth
                                    label="Tipo de material"
                                    value={subcategory}
                                    onChange={(e) => {
                                        setReference("none");
                                        setSubcategory(e.target.value);
                                        setErrors(prevErrors => ({ ...prevErrors, subcategory: '' }));
                                    }}
                                    error={!!errors.subcategory}
                                    helperText={errors.subcategory}
                                    variant="outlined"
                                    disabled={formLoading || formSubmitted}
                                >
                                    <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                                    <MenuItem value="article">Artículo</MenuItem>
                                    <MenuItem value="congress_minute">Acta de congreso</MenuItem>
                                    <MenuItem value="technical_report">Reporte técnico</MenuItem>
                                    <MenuItem value="poster">Póster</MenuItem>
                                    <MenuItem value="book">Libro</MenuItem>
                                    <MenuItem value="book_chapter">Capítulo de libro</MenuItem>
                                    <MenuItem value="website">Página web</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={4} mt={"20px"}>
                                <TextField
                                    select
                                    fullWidth
                                    label="Visibilidad"
                                    value={visibility}
                                    onChange={(e) => {
                                        setVisibility(e.target.value);
                                        setErrors(prevErrors => ({ ...prevErrors, visibility: '' }));
                                    }}
                                    error={!!errors.visibility}
                                    helperText={errors.visibility}
                                    variant="outlined"
                                    disabled={formLoading || formSubmitted}
                                >
                                    <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                                    <MenuItem value="public">Público</MenuItem>
                                    <MenuItem value="semipublic">Semi-Público</MenuItem>
                                    <MenuItem value="private">Privado</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={2} alignItems="center">
                            {(subcategory !== "website" && subcategory !== "none") && (
                                <Grid item xs={12} sm={4} mt={"20px"}>
                                    <TextField
                                        select
                                        fullWidth
                                        label="¿Como acceder a este material?"
                                        value={reference}
                                        onChange={(e) => {
                                            if (e.target.value === 'doi') {
                                                setFile(null);
                                                setResourceUrl('');
                                            } else if (e.target.value === 'url') {
                                                setDOI('');
                                                setFile(null);
                                            } else if (e.target.value === 'direct') {
                                                setDOI('');
                                                setResourceUrl('');
                                            }
                                            setReference(e.target.value);
                                            setErrors(prevErrors => ({ ...prevErrors, reference: '' }));
                                        }}
                                        error={!!errors.reference}
                                        helperText={errors.reference}
                                        variant="outlined"
                                        disabled={formLoading || formSubmitted}
                                    >
                                        <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                                        {(subcategory !== "website" && subcategory !== "technical_report") && <MenuItem value="doi">DOI</MenuItem>}
                                        <MenuItem value="url">URL</MenuItem>
                                        {(subcategory === "congress_minute" || subcategory === "technical_report" || subcategory === "poster") && <MenuItem value="direct">Archivo</MenuItem>}
                                    </TextField>
                                </Grid>
                            )}
                            {(reference === "doi" && subcategory !== "website") && (
                                <Grid item xs={loadingDOI ? 11 : 12} sm={loadingDOI ? 7 : 8} mt={"20px"}>
                                    <TextField
                                        fullWidth
                                        label="DOI"
                                        value={doi}
                                        onBlur={(e) => {
                                            handleDOI(e.target.value);
                                        }}
                                        onChange={(e) => {
                                            setDOI(e.target.value);
                                            setErrors(prevErrors => ({ ...prevErrors, doi: '' }));
                                        }}
                                        error={!!errors.doi}
                                        helperText={errors.doi}
                                        variant="outlined"
                                        disabled={formLoading || formSubmitted || loadingDOI}
                                    >
                                    </TextField>
                                </Grid>
                            )}
                            {(reference === "doi" && subcategory !== "website" && loadingDOI) && (
                                <Grid item xs={1} textAlign={"right"} mt={"20px"}>
                                    <CircularProgress sx={{ "color": "#40b4ba" }} />
                                </Grid>
                            )}
                            {(subcategory === "website" || reference === "url") && (
                                <Grid item xs={12} sm={(reference === "url" && subcategory !== "website") ? 8 : 12} mt={"20px"}>
                                    <TextField
                                        label="URL del recurso"
                                        component="label"
                                        variant="outlined"
                                        fullWidth
                                        value={resourceUrl}
                                        tabIndex={-1}
                                        onChange={(e) => setResourceUrl(e.target.value)}
                                        error={!!errors.resourceUrl}
                                        helperText={errors.resourceUrl}
                                        disabled={formLoading || formSubmitted}
                                    />
                                </Grid>
                            )}
                            {(subcategory !== "website" && reference === "direct") && (
                                <Grid item xs={12} sm={4} mt={"20px"}>
                                    <Grid>
                                        <Button
                                            component="label"
                                            role={undefined}
                                            variant="orange"
                                            tabIndex={-1}
                                            onChange={handleFileChange}
                                            startIcon={<CloudUploadIcon />}
                                            disabled={formLoading || formSubmitted}
                                        >
                                            Subir archivo
                                            <FileUpload type="file" onChange={handleFileChange} accept={".pdf"} />
                                        </Button>
                                        <Typography ml={"8px"} sx={{ "color": "#646464", "fontFamily": "Avenir Regular" }} variant="caption">
                                            {file && (
                                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                                    <Typography sx={{ "color": "#646464", "fontFamily": "Avenir Regular" }} variant="caption">
                                                        {file ? `${file.name} (${formatFileSize(file.size)})` : "Por favor seleccione un archivo..."}
                                                    </Typography>
                                                    <IconButton disabled={formLoading || formSubmitted} sx={{ marginLeft: "10px" }} size="small" onClick={resetFileInput}>
                                                        <CloseIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Box>
                                            )}
                                        </Typography>
                                        {errors.file ? <Typography sx={{ marginTop: "10px", fontSize: "0.75rem" }} width={"100%"} variant="p" color="error">{errors.file}</Typography> : <></>}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        <Grid container columnSpacing={2} alignItems="center" marginTop="5px">
                            <Grid item xs={12} sm={8} mt={"20px"}>
                                <TextField
                                    fullWidth
                                    label="Título"
                                    variant="outlined"
                                    value={title}
                                    onChange={(e) => {
                                        setTitle(e.target.value);
                                        setErrors(prevErrors => ({ ...prevErrors, title: '' }));
                                    }}
                                    error={!!errors.title}
                                    helperText={errors.title}
                                    disabled={loadingDOI || formLoading || formSubmitted}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} mt={"20px"}>
                                <TextField
                                    fullWidth
                                    label="Autor(es)"
                                    variant="outlined"
                                    value={author}
                                    onChange={(e) => {
                                        setAuthor(e.target.value);
                                        setErrors(prevErrors => ({ ...prevErrors, author: '' }));
                                    }}
                                    error={!!errors.author}
                                    helperText={errors.author}
                                    disabled={loadingDOI || formLoading || formSubmitted}
                                />
                            </Grid>
                        </Grid>
                        {(subcategory === 'book' || subcategory === 'book_chapter') && (
                            <Grid container columnSpacing={2} alignItems="center">
                                <Grid item xs={12} mt={"20px"}>
                                    <TextField
                                        fullWidth
                                        label="Subtítulo"
                                        variant="outlined"
                                        value={subtitle}
                                        onChange={(e) => {
                                            setSubtitle(e.target.value);
                                            setErrors(prevErrors => ({ ...prevErrors, subtitle: '' }));
                                        }}
                                        error={!!errors.subtitle}
                                        helperText={errors.subtitle}
                                        disabled={loadingDOI || formLoading || formSubmitted}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        <Grid container columnSpacing={2} alignItems="center">
                            <Grid item xs={12} mt={"20px"}>
                                <TextField
                                    fullWidth
                                    label={subcategory === 'article' ? "Resumen" : "Descripción"}
                                    variant="outlined"
                                    multiline
                                    rows={6}
                                    value={description}
                                    onChange={(e) => {
                                        setDescription(e.target.value);
                                        setErrors(prevErrors => ({ ...prevErrors, description: '' }));
                                    }}
                                    error={!!errors.description}
                                    helperText={errors.description}
                                    disabled={loadingDOI || formLoading || formSubmitted}
                                />
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={2} alignItems="center">
                            {subcategory !== "poster" &&
                                <Grid item xs={12} sm={6} mt={"20px"}>
                                    <TextField
                                        fullWidth
                                        label="Editor"
                                        variant="outlined"
                                        value={editor}
                                        onChange={(e) => {
                                            setEditor(e.target.value);
                                            setErrors(prevErrors => ({ ...prevErrors, editor: '' }));
                                        }}
                                        error={!!errors.editor}
                                        helperText={errors.editor}
                                        disabled={loadingDOI || formLoading || formSubmitted}
                                    />
                                </Grid>
                            }
                            <Grid item xs={6} sm={3} mt={"20px"}>
                                <TextField
                                    fullWidth
                                    label="Año"
                                    variant="outlined"
                                    value={year}
                                    onChange={(e) => {
                                        setYear(e.target.value);
                                        setErrors(prevErrors => ({ ...prevErrors, year: '' }));
                                    }}
                                    error={!!errors.year}
                                    helperText={errors.year}
                                    disabled={loadingDOI || formLoading || formSubmitted}
                                />
                            </Grid>
                            {(subcategory === 'article' || subcategory === 'book_chapter') && (
                                <Grid item xs={6} sm={3} mt={"20px"}>
                                    <TextField
                                        fullWidth
                                        label="Páginas"
                                        variant="outlined"
                                        value={pages}
                                        onChange={(e) => {
                                            setPages(e.target.value);
                                            setErrors(prevErrors => ({ ...prevErrors, pages: '' }));
                                        }}
                                        error={!!errors.pages}
                                        helperText={errors.pages}
                                        disabled={loadingDOI || formLoading || formSubmitted}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        {(subcategory !== "website" && subcategory !== "poster") &&
                            <Grid container columnSpacing={2} alignItems="center">
                                <Grid item xs={12} sm={6} mt={"20px"}>
                                    <TextField
                                        fullWidth
                                        label="Revista"
                                        variant="outlined"
                                        value={journal}
                                        onChange={(e) => {
                                            setJournal(e.target.value);
                                            setErrors(prevErrors => ({ ...prevErrors, journal: '' }));
                                        }}
                                        error={!!errors.journal}
                                        helperText={errors.journal}
                                        disabled={loadingDOI || formLoading || formSubmitted}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3} mt={"20px"}>
                                    <TextField
                                        fullWidth
                                        label="Volumen"
                                        variant="outlined"
                                        value={volume}
                                        onChange={(e) => {
                                            setVolume(e.target.value);
                                            setErrors(prevErrors => ({ ...prevErrors, volume: '' }));
                                        }}
                                        error={!!errors.volume}
                                        helperText={errors.volume}
                                        disabled={loadingDOI || formLoading || formSubmitted}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3} mt={"20px"}>
                                    <TextField
                                        fullWidth
                                        label="Número"
                                        variant="outlined"
                                        value={issue}
                                        onChange={(e) => {
                                            setIssue(e.target.value);
                                            setErrors(prevErrors => ({ ...prevErrors, issue: '' }));
                                        }}
                                        error={!!errors.issue}
                                        helperText={errors.issue}
                                        disabled={loadingDOI || formLoading || formSubmitted}
                                    />
                                </Grid>
                            </Grid>
                        }
                        <Grid container columnSpacing={2} alignItems="center">
                            <Grid item xs={12} mt={"20px"}>
                                <TextField
                                    fullWidth
                                    label="¿Cómo citar este recurso?"
                                    variant="outlined"
                                    multiline
                                    rows={6}
                                    value={justification}
                                    onChange={(e) => {
                                        setJustification(e.target.value);
                                        setErrors(prevErrors => ({ ...prevErrors, justification: '' }));
                                    }}
                                    error={!!errors.justification}
                                    helperText={errors.justification}
                                    disabled={formLoading || formSubmitted || loadingDOI}
                                />
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={2} alignItems="center" justifyContent={"center"}>
                            <Grid item mt={"20px"}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={consent}
                                                onChange={
                                                    (e) => {
                                                        setConsent(e.target.checked);
                                                        setErrors(prevErrors => ({ ...prevErrors, consent: '' }));
                                                    }
                                                }
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        } sx={{ "color": '#646464' }} label="Declaro estar autorizado/a para compartir este material." />
                                    {errors.consent && <span style={{ color: '#d32f2f', fontSize: '0.75rem', textAlign: "center" }}>{errors.consent}</span>}
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Box textAlign={"center"} >
                            <Button sx={{"fontSize": {"xs": "0.7em", "sm": "0.9em"}}} type="submit" variant="green" disabled={formLoading || formSubmitted} style={{ margin: "30px 0 20px 0" }} startIcon={<HowToRegIcon />}>
                                {(formLoading) ? "Enviando..." : (formSubmitted) ? "Recurso propuesto" : "Proponer recurso"}
                            </Button>
                        </Box>
                        {formSubmitted && (
                            <Box textAlign={"center"}>
                                <Typography variant='p' fontSize={"0.85em"} textAlign={"center"}>Gracias por colaborar con RedFID. El recurso ha sido propuesto exitosamente.</Typography>
                                <Typography variant='p' fontSize={"0.85em"} textAlign={"center"}>Serás notificado cuando esté disponible en la plataforma.</Typography>
                                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                    <Typography onClick={() => {keycloak.didInitialize && navigate("/me")}} variant='a' fontSize={"0.85em"} textAlign={"center"} marginRight={"15px"}>Ir a Mis Solicitudes</Typography>
                                    <Typography onClick={() => {keycloak.didInitialize && resetForm()}} variant='a' fontSize={"0.85em"} textAlign={"center"} marginLeft={"15px"}>Proponer otro recurso</Typography>
                                </Box>
                            </Box>
                        )}
                        {formFailed && (
                            <Box textAlign={"center"}>
                                <Typography variant='error' fontSize={"0.85em"} textAlign={"center"}>{formError}</Typography>
                            </Box>
                        )}
                    </form>
                </>
                ) : keycloak.didInitialize ? (
                    <Box textAlign={"center"}>
                        <Typography variant='title' textAlign={"center"}>No tienes permiso para ver esta página.</Typography>
                        <Typography variant='subtitle' textAlign={"center"}>Por favor inicie sesión.</Typography>
                        <Typography onClick={() => {keycloak.didInitialize && navigate("/")}} variant='a'>Volver al Home</Typography>
                    </Box>
                ) : <></>}
            </Grid>
            <Footer />
        </Grid>
    );
}

export default FormularioMaterialAcademico;