import React, { useState, useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import axios from 'axios';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import materialAcademicoImage from '../../assets/img/caluga_01.png';
import materialDocenteImage from '../../assets/img/caluga_02.png';
import difusionImage from '../../assets/img/caluga_03.png';
import HomeFeaturedMain from '../components/HomeFeaturedMain';
import HomeFeaturedSide from '../components/HomeFeaturedSide';
import { CircularProgress } from '@mui/material';
import api from '../../API';


const Home = ({ keycloak }) => {

  const navigate = useNavigate();
  const { trackPageView } = useMatomo()

  const [loadingFeatured, setLoadingFeatured] = useState(true);
  const [featured, setFeatured] = useState(null);
  const [mainFeatured, setMainFeatured] = useState(null);
  const [search, setSearch] = useState("");

  const handleSearch = () => {
    navigate(`/recursos?s=${search}`);
  }

  const loadFeatured = () => {
    document.title = "RedFID | Recursos";
    const header = keycloak.authenticated ? { headers: { 'Authorization': 'Bearer ' + keycloak.token } } : {}
    axios.get(api.api_url + (keycloak.authenticated ? "/recursos_featured_private/" : "/recursos_featured/"), header)
      .then(response => {
        setFeatured(response.data.filter(item => !item.main_featured));
        setMainFeatured(response.data.filter(item => item.main_featured)[0]);
        setLoadingFeatured(false);
      })
      .catch(error => {
        console.log(error)
      });
  }

  useEffect(() => {
    if (keycloak.didInitialize){
      loadFeatured();
      trackPageView();
    }
  }, [keycloak.authenticated])


  return (
    <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', alignItems: "center" }}>
      <Header keycloak={keycloak} />
      <Grid
        component="div"
        sx={{
          flexGrow: 1,
          maxWidth: '940px',
          margin: "30px 20px",
        }}
      >
        <Grid mb={"20px"} container display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <Grid item>
            <Box display={"flex"}>
              <Typography sx={{ fontWeight: "normal", fontSize: { sm: '1.6em', xs: '1.2em' }, marginRight: "10px" }}>RedFID |</Typography>
              <Typography sx={{ fontFamily: "Avenir Heavy !important", fontWeight: "bold", fontSize: { sm: '1.6em', xs: '1.2em' } }}>Recursos</Typography>
            </Box>
          </Grid>
          {keycloak.authenticated &&
              <Grid item onClick={() => {keycloak.didInitialize && navigate("/me")}} sx={{ 
                "backgroundColor": "#40b4ba", 
                "display": "flex", 
                "justifyContent": "space-between", 
                "alignItems": "center", 
                "padding": {"xs": "0px 10px 0px 0px", "sm": "5px 15px 5px 5px"}, 
                "borderRadius": "5px", 
                "&:hover": { "cursor": "pointer" } 
              }} >
                <IconButton disableFocusRipple={true} disableRipple={true} sx={{ "color": "white" }}>
                  <DriveFolderUploadIcon />
                </IconButton>
                <Typography fontSize={{"xs": "0.8em", "sm": "0.9em"}} color={"white"} fontWeight={"bold"}>Colaborar</Typography>
              </Grid>
          }
        </Grid>
        <Typography fontSize={{"xs": "0.8em", "sm": "0.85em"}} variant='p' mb={"20px"}>Accede a una amplia biblioteca digital de recursos relacionados con la educación en matemática, formación docente inicial de profesores de matemática y al aprendizaje profesional de los formadores.</Typography>
        <Grid container alignItems="start" display={"flex"} justifyContent={"space-between"} m={"5px 0 0 0"}>
          <Grid item xs={12} sm={8}>
            {loadingFeatured ? <Box textAlign={"center"} pb={"40px"}><CircularProgress sx={{ "color": "#40b4ba" }} /></Box> : <HomeFeaturedMain keycloak={keycloak} item={mainFeatured} />}
          </Grid>
          <Grid item xs={12} sm={3.8}>
            <TextField
              id="input-with-icon-textfield"
              fullWidth
              label={"Buscar..."}
              onKeyDown={(e) => { if (e.key === 'Enter') { handleSearch() } }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              sx={{
                "marginBottom": "20px"
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleSearch()}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
            {loadingFeatured ? <Box textAlign={"center"}><CircularProgress sx={{ "color": "#40b4ba" }} /></Box> : <HomeFeaturedSide keycloak={keycloak} featured={featured} />}
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center" justifyContent={"space-between"} marginTop="5px">
          <Grid item xs={12} sm={4}>
            <Box sx={{"cursor": "pointer"}} onClick={() => {keycloak.didInitialize && navigate("/academico")}}>
              <Box
                component="img"
                sx={{
                  width: "100%"
                }}
                alt="Material académico"
                src={materialAcademicoImage}
              />
              <Box sx={{ "backgroundColor": "#eb947e", "padding": "8px", "marginTop": "-20px", "position": "relative" }}>
                <Typography sx={{ "fontSize": {"lg": "1em", "md": "0.8em", "sm": "0.65em", "xs": "1em"}, "textAlign": "center", "color": "white", "fontWeight": "bold", "fontFamily": "Avenir Heavy !important" }}>INVESTIGACIÓN</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
          <Box sx={{"cursor": "pointer"}} onClick={() => {keycloak.didInitialize && navigate("/docente")}}>
              <Box
                component="img"
                sx={{
                  cursor: 'pointer',
                  width: "100%"
                }}
                alt="Material docente"
                src={materialDocenteImage}
              />
              <Box sx={{ "backgroundColor": "#eb947e", "padding": "8px", "marginTop": "-20px", "position": "relative" }}>
                <Typography sx={{ "fontSize": {"lg": "1em", "md": "0.8em", "sm": "0.65em", "xs": "1em"}, "textAlign": "center", "color": "white", "fontWeight": "bold", "fontFamily": "Avenir Heavy !important" }}>MATERIAL DOCENTE</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
          <Box sx={{"cursor": "pointer"}} onClick={() => {keycloak.didInitialize && navigate("/difusion")}}>
              <Box
                component="img"
                sx={{
                  cursor: 'pointer',
                  width: "100%"
                }}
                alt="Línea gráfica y difusión"
                src={difusionImage}
              />
              <Box sx={{ "backgroundColor": "#eb947e", "padding": "8px", "marginTop": "-20px", "position": "relative" }}>
                <Typography sx={{ "fontSize": {"lg": "1em", "md": "0.8em", "sm": "0.65em", "xs": "1em"}, "textAlign": "center", "color": "white", "fontWeight": "bold", "fontFamily": "Avenir Heavy !important" }}>LÍNEA GRÁFICA Y DIFUSIÓN</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
}

export default Home;