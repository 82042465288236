import React, { useState, useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import axios from 'axios';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MaterialDocenteAuthors from '../components/MaterialDocenteAuthors';
import MaterialDocenteFiles from '../components/MaterialDocenteFiles';
import api from '../../API';
import { useNavigate } from 'react-router-dom';


const FormularioMaterialDocente = ({ keycloak }) => {

    const navigate = useNavigate();
    const { trackPageView } = useMatomo();

    const [consent, setConsent] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [formLoadingAuthors, setFormLoadingAuthors] = useState(false);
    const [formLoadingFiles, setFormLoadingFiles] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formFailed, setFormFailed] = useState(false);
    const [formFeedback, setFormFeedback] = useState('');
    const [formError, setFormError] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [visibility, setVisibility] = useState('none');
    const [category, setCategory] = useState('none');
    const [academicLevel, setAcademicLevel] = useState('none');
    const [stage, setStage] = useState('none');
    const [justification, setJustification] = useState('');
    const [files, setFiles] = useState([{ type: 'none', filename: '', file: null, reference: 'none', url: '' }]);
    const [authors, setAuthors] = useState([{ author: '', institution: '' }]);
    const [errors, setErrors] = useState({ "authors": [{ author: '', institution: '' }], "files": [{ type: '', reference: '', url: '', file: '' }] });

    const validateForm = () => {
        let tempErrors = {
            "authors": [{ author: '', institution: '' }],
            "files": [{ type: '', file: '', reference: '', url: '' }],
        };
        const urlPattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        let isValid = true;
        if (category === 'none') {
            tempErrors.category = "Por favor indique una categoría.";
            isValid = false;
        }
        if (stage === 'none') {
            tempErrors.stage = "Por favor seleccione una etapa formativa.";
            isValid = false;
        }
        if (academicLevel === 'none') {
            tempErrors.academicLevel = "Por favor seleccione un nivel académico.";
            isValid = false;
        }
        if (visibility === 'none') {
            tempErrors.visibility = "Por favor seleccione un tipo de visibilidad.";
            isValid = false;
        }
        if (title.length > 500 || title.length < 5) {
            tempErrors.title = "El título debe tener entre 5 y 500 caracteres (actual: " + title.length + ").";
            isValid = false;
        }
        if (!title) {
            tempErrors.title = "Por favor indique el título del recurso.";
            isValid = false;
        }
        authors.map((author, index) => {
            tempErrors.authors[index] = {}
            if (author.author.length > 100 || author.author.length < 3) {
                tempErrors.authors[index].author = "El nombre del autor debe tener entre 3 y 100 caracteres (actual: " + author.author.length + ").";
                isValid = false;
            }
            if (author.author === "") {
                tempErrors.authors[index].author = "Por favor indique el nombre del autor.";
                isValid = false;
            }
            if (tempErrors.authors[index].author === "") {
                tempErrors.authors[index].author = "";
            }
            if (author.institution.length > 100 || author.institution.length < 2) {
                tempErrors.authors[index].institution = "La afiliación del autor debe tener entre 2 y 100 caracteres (actual: " + author.institution.length + ").";
                isValid = false;
            }
            if (author.institution === "") {
                tempErrors.authors[index].institution = "Por favor indique la afiliación del autor.";
                isValid = false;
            }
            if (tempErrors.authors[index].institution === "") {
                tempErrors.authors[index].institution = "";
            }
        });
        files.map((file, index) => {
            tempErrors.files[index] = {}
            if (file.type === 'none') {
                tempErrors.files[index].type = "Por favor seleccione un tipo de archivo.";
                isValid = false;
            }
            if (file.reference === 'none') {
                tempErrors.files[index].reference = "Por favor seleccione una referencia.";
                isValid = false;
            }
            if (file.reference === 'subir_archivo') {
                if (file.file == null) {
                    tempErrors.files[index].file = "Por favor adjunte un archivo.";
                    isValid = false;
                }
                if (file.file && file.file.size > 1024 * 1024 * 1024) {
                    tempErrors.files[index].file = "El tamaño máximo del archivo es de 1 GB.";
                    isValid = false;
                }
            }
            if (file.reference === 'url') {
                if (file.url.length > 500 || file.url.length < 5) {
                    tempErrors.files[index].url = "La URL debe tener entre 5 y 500 caracteres (actual: " + file.url.length + ").";
                    isValid = false;
                }
                if (!urlPattern.test(file.url)) {
                    tempErrors.files[index].url = "La URL no es válida.";
                    isValid = false;
                }
                if (file.url === "") {
                    tempErrors.files[index].url = "Por favor indique la URL del recurso.";
                    isValid = false;
                }
            }
        });
        if (description.length > 10000 || description.length < 10) {
            tempErrors.description = "La descripción debe tener entre 10 y 10000 caracteres (actual: " + description.length + ").";
            isValid = false;
        }
        if (!description) {
            tempErrors.description = "Por favor indique la descripción del recurso.";
            isValid = false;
        }
        if (justification.length > 10000 || justification.length < 10) {
            tempErrors.justification = "La explicación debe tener entre 10 y 10000 caracteres (actual: " + justification.length + ").";
            isValid = false;
        }
        if (!justification) {
            tempErrors.justification = "Por favor indique para qué sirve este material en el contexto de formación inicial docente.";
            isValid = false;
        }
        if (!consent) {
            tempErrors.consent = "Por favor declare que está autorizado/a para compartir este material.";
            isValid = false;
        }
        if (Object.keys(files).length === 0) {
            tempErrors.files = "Por favor adjunte al menos un archivo.";
            isValid = false;
        }
        setErrors({ ...tempErrors });
        if (!isValid) {
            setFormError("Hay errores en el formulario.");
            setFormFailed(true);
        }
        return isValid;
    };

    const resetForm = () => {
        setConsent(false);
        setFormLoading(false);
        setFormLoadingAuthors(false);
        setFormLoadingFiles(false);
        setFormSubmitted(false);
        setFormFailed(false);
        setFormFeedback('');
        setFormError('');
        setTitle('');
        setDescription('');
        setVisibility('none');
        setCategory('none');
        setAcademicLevel('none');
        setStage('none');
        setJustification('');
        setFiles([{ type: 'none', filename: '', file: null, reference: 'none', url: '' }]);
        setAuthors([{ author: '', institution: '' }]);
        setErrors({ "authors": [{ author: '', institution: '' }], "files": [{ type: '', file: '', reference: '', url: '' }] });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setFormLoading(true);
        setFormFeedback("Enviando datos...");
        setFormFailed(false);
        setFormError('');
        if (validateForm()) {
            var url = "/activity/";
            const formData = new FormData();
            formData.append('username', keycloak.idTokenParsed.preferred_username);
            formData.append('first_name', keycloak.idTokenParsed.given_name);
            formData.append('last_name', keycloak.idTokenParsed.family_name);
            formData.append('classification', category);
            formData.append('level', academicLevel);
            formData.append('stage', stage);
            formData.append('visibility', visibility);
            formData.append('title', title);
            formData.append('description', description);
            formData.append('utility', justification);
    
            axios.post(api.api_url + url, formData, { headers: { 'Authorization': 'Bearer ' + keycloak.token } })
                .then(response => {
                    if (response.status === 200) {
                        const activityId = response.data.id;
    
                        const authorPromises = authors.map(author => {
                            const authorData = new FormData();
                            authorData.append('author', author.author);
                            authorData.append('institution', author.institution);
                            authorData.append('activity_id', activityId);
                            return axios.post(api.api_url + '/activity_author/', authorData, { headers: { 'Authorization': 'Bearer ' + keycloak.token } });
                        });
    
                        const filePromises = files.map(file => {
                            const fileData = new FormData();
                            fileData.append('type', file.type);
                            fileData.append('reference', file.reference);
                            fileData.append('url', file.url);
                            fileData.append('activity_id', activityId);
                            fileData.append('filename', file.file ? file.filename : "");
                            fileData.append('filesize', file.file ? file.file.size.toString() : "");
                            if (file.file) {
                                fileData.append('file', file.file);
                            }
                            return axios.post(api.api_url + '/recurso_docente/', fileData, { headers: { 'Authorization': 'Bearer ' + keycloak.token } });
                        });
    
                        Promise.all([...authorPromises, ...filePromises])
                            .then(() => {
                                setFormSubmitted(true);
                            })
                            .catch(error => {
                                console.log(error)
                                setFormFailed(true);
                                setFormError('Ha ocurrido un error desconocido. Por favor contacte a administración.');
                            })
                            .finally(() => {
                                setFormLoading(false);
                            });
                    } else if (response.status === 490) {
                        setFormError('No se encuentra el usuario. Este error no debería ocurrir. Por favor contacte a administración.');
                        setFormFailed(true);
                        setFormLoading(false);
                    } else {
                        console.log(response)
                        setFormFailed(true);
                        setFormError('Ha ocurrido un error desconocido. Por favor contacte a administración.');
                        setFormLoading(false);
                    }
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        setFormError('Error: ' + error.response.data.message);
                    } else if (error.response.status === 490) {
                        setFormError('No se encuentra el usuario. Este error no debería ocurrir. Por favor contacte a administración.');
                    } else if (error.response.status  === 491) {
                        setFormError('Este recurso ya se encuentra en RedFID Recursos.');
                    } else {
                        setFormError('Ha ocurrido un error desconocido. Por favor contacte a administración.');
                    }
                    setFormFailed(true);
                    setFormLoading(false);
                });
        } else {
            setFormLoading(false);
        }
    };

    useEffect(() => {
        if (keycloak.didInitialize) {
            trackPageView();
        }
    }, [keycloak])

    useEffect(() => {
        document.title = "Colaborar con material docente | RedFID Recursos";
    }, [])

    return (
        <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', alignItems: 'center' }}>
            <Header keycloak={keycloak} />
            <Grid
                component="div"
                sx={{
                flexGrow: 1,
                maxWidth: '940px',
                width: '100%',
                padding: "30px 20px",
                }}
            >
                {keycloak.authenticated ? (<>
                    <Typography onClick={() => {keycloak.didInitialize && navigate("/me")}} href={"/me"} style={{ display: 'flex', alignItems: 'center', color: '#40b4ba', 'width': 'fit-content' }}>
                        <ArrowBackIcon sx={{ fontSize: '1em' }} variant="back" />
                        <Typography variant="back">Volver</Typography>
                    </Typography>
                    <Typography sx={{"fontSize": {"xs": "1em", "sm": "1.4em"}}} variant="title">Colaborar con material docente</Typography>
                    <Typography fontSize={{"xs": "0.8em", "sm": "0.85em"}} variant='p' m={"20px auto"}>Comparta material docente con el resto de la comunidad.</Typography>
                    <form onSubmit={handleSubmit}>
                        <Typography sx={{"fontSize": {"xs": "1.2em", "sm": "1.4em"}}} variant='subtitle' marginTop="20px">Generalidades</Typography>
                        <Grid container columnSpacing={2} alignItems="center">
                            <Grid item xs={12} sm={4} mt={"20px"}>
                                <TextField
                                    select
                                    fullWidth
                                    label="Categoría"
                                    value={category}
                                    onChange={(e) => {
                                        setCategory(e.target.value);
                                        setErrors(prevErrors => ({ ...prevErrors, category: '' }));
                                    }}
                                    error={!!errors.category}
                                    helperText={errors.category}
                                    variant="outlined"
                                    disabled={formLoading || formSubmitted}
                                >
                                    <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                                    <MenuItem value="matematica">Matemática</MenuItem>
                                    <MenuItem value="didactica_de_la_matematica">Didáctica de la matemática</MenuItem>
                                    <MenuItem value="formacion_pedagogica">Formación pedagógica</MenuItem>
                                    <MenuItem value="formacion_practica">Formación práctica</MenuItem>
                                    <MenuItem value="otras_disciplinas">Otras disciplinas</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={4} mt={"20px"}>
                                <TextField
                                    select
                                    fullWidth
                                    label="Formación docente para..."
                                    value={academicLevel}
                                    onChange={(e) => {
                                        setAcademicLevel(e.target.value);
                                        setErrors(prevErrors => ({ ...prevErrors, academicLevel: '' }));
                                    }}
                                    error={!!errors.academicLevel}
                                    helperText={errors.academicLevel}
                                    variant="outlined"
                                    disabled={formLoading || formSubmitted}
                                >
                                    <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                                    <MenuItem value="educacion_parvularia">Educación parvularia</MenuItem>
                                    <MenuItem value="educacion_basica">Educación básica</MenuItem>
                                    <MenuItem value="educacion_media">Educación media</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={4} mt={"20px"}>
                                <TextField
                                    select
                                    fullWidth
                                    label="Etapa formativa"
                                    value={stage}
                                    onChange={(e) => {
                                        setStage(e.target.value);
                                        setErrors(prevErrors => ({ ...prevErrors, stage: '' }));
                                    }}
                                    error={!!errors.stage}
                                    helperText={errors.stage}
                                    variant="outlined"
                                    disabled={formLoading || formSubmitted}
                                >
                                    <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                                    <MenuItem value="formacion_inicial">Formación inicial</MenuItem>
                                    <MenuItem value="formacion_continua">Formación continua</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={2} alignItems="center">
                            <Grid item xs={12} sm={4} mt={"20px"}>
                                <TextField
                                    select
                                    fullWidth
                                    label="Visibilidad"
                                    value={visibility}
                                    onChange={(e) => {
                                        setVisibility(e.target.value);
                                        setErrors(prevErrors => ({ ...prevErrors, visibility: '' }));
                                    }}
                                    error={!!errors.visibility}
                                    helperText={errors.visibility}
                                    variant="outlined"
                                    disabled={formLoading || formSubmitted}
                                >
                                    <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                                    <MenuItem value="public">Público</MenuItem>
                                    <MenuItem value="semipublic">Semi-Público</MenuItem>
                                    <MenuItem value="private">Privado</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={2} alignItems="center">
                            <Grid item xs={12} mt={"20px"}>
                                <TextField
                                    fullWidth
                                    label="Título"
                                    variant="outlined"
                                    value={title}
                                    onChange={(e) => {
                                        setTitle(e.target.value);
                                        setErrors(prevErrors => ({ ...prevErrors, title: '' }));
                                    }}
                                    error={!!errors.title}
                                    helperText={errors.title}
                                    disabled={formLoading || formSubmitted}
                                />
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={2} alignItems="center">
                            <Grid item xs={12} mt={"20px"}>
                                <TextField
                                    fullWidth
                                    label={"Descripción"}
                                    variant="outlined"
                                    multiline
                                    rows={6}
                                    value={description}
                                    onChange={(e) => {
                                        setDescription(e.target.value);
                                        setErrors(prevErrors => ({ ...prevErrors, description: '' }));
                                    }}
                                    error={!!errors.description}
                                    helperText={errors.description}
                                    disabled={formLoading || formSubmitted}
                                />
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={2} alignItems="center">
                            <Grid item xs={12} mt={"20px"}>
                                <TextField
                                    fullWidth
                                    label="¿Para qué sirve este material?"
                                    variant="outlined"
                                    multiline
                                    rows={6}
                                    value={justification}
                                    onChange={(e) => {
                                        setJustification(e.target.value);
                                        setErrors(prevErrors => ({ ...prevErrors, justification: '' }));
                                    }}
                                    error={!!errors.justification}
                                    helperText={errors.justification}
                                    disabled={formLoading || formSubmitted}
                                />
                            </Grid>
                        </Grid>
                        <Typography sx={{"fontSize": {"xs": "1.2em", "sm": "1.4em"}}} variant='subtitle' marginTop="20px">Autores</Typography>
                        <MaterialDocenteAuthors authors={authors} setAuthors={setAuthors} errors={errors} setErrors={setErrors} formLoading={formLoading} formSubmitted={formSubmitted} />
                        <Typography sx={{"fontSize": {"xs": "1.2em", "sm": "1.4em"}}} variant='subtitle' marginTop="20px">Recursos</Typography>
                        <MaterialDocenteFiles files={files} setFiles={setFiles} errors={errors} setErrors={setErrors} formLoading={formLoading} formSubmitted={formSubmitted} />
                        <Grid container spacing={2} alignItems="center" justifyContent={"center"} marginTop="5px">
                            <Grid item>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={consent}
                                                onChange={
                                                    (e) => {
                                                        setConsent(e.target.checked);
                                                        setErrors(prevErrors => ({ ...prevErrors, consent: '' }));
                                                    }
                                                }
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        } sx={{ "color": '#646464' }} label="Declaro estar autorizado/a para compartir este material." />
                                    {errors.consent && <span style={{ color: '#d32f2f', fontSize: '0.75rem', textAlign: "center" }}>{errors.consent}</span>}
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Box textAlign={"center"} >
                            <Button sx={{"fontSize": {"xs": "0.7em", "sm": "0.9em"}}} type="submit" variant="green" disabled={formLoading || formSubmitted} style={{ margin: "30px 0 20px 0" }} startIcon={<HowToRegIcon />}>
                                {(formLoading) ? "Enviando..." : (formSubmitted) ? "Recurso propuesto" : "Proponer recurso"}
                            </Button>
                        </Box>
                        {formSubmitted && (
                            <Box textAlign={"center"}>
                                <Typography variant='p' fontSize={"0.85em"} textAlign={"center"}>Gracias por colaborar con RedFID. El recurso ha sido propuesto exitosamente.</Typography>
                                <Typography variant='p' fontSize={"0.85em"} textAlign={"center"}>Serás notificado cuando esté disponible en la plataforma.</Typography>
                                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                    <Typography onClick={() => {keycloak.didInitialize && navigate("/me")}} variant='a' fontSize={"0.85em"} textAlign={"center"} marginRight={"15px"}>Ir a Mis Solicitudes</Typography>
                                    <Typography onClick={() => {keycloak.didInitialize && resetForm()}} variant='a' fontSize={"0.85em"} textAlign={"center"} marginLeft={"15px"}>Proponer otro recurso</Typography>
                                </Box>
                            </Box>
                        )}
                        {formFailed && (
                            <Box textAlign={"center"}>
                                <Typography variant='error' fontSize={"0.85em"} textAlign={"center"}>{formError}</Typography>
                            </Box>
                        )}
                    </form>
                </>
                ) : keycloak.didInitialize ? (
                    <Box textAlign={"center"}>
                        <Typography variant='title' textAlign={"center"}>No tienes permiso para ver esta página.</Typography>
                        <Typography variant='subtitle' textAlign={"center"}>Por favor inicie sesión.</Typography>
                        <Typography onClick={() => {keycloak.didInitialize && navigate("/")}} variant='a'>Volver al Home</Typography>
                    </Box>
                ) : <></>}
            </Grid>
            <Footer />
        </Grid>
    );
}

export default FormularioMaterialDocente;